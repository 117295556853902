.report-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0px;
  border-bottom: 2px solid #ccc;
}

.report-left-side {
  flex: 1;
  text-align: left;
}

.report-right-side {
  flex: 1;
  text-align: right;
}

.report-logo {
  max-height: 50px;
  /* Corrected to 'max-height' */
}

.report-left-side h1 {
  margin-top: 0px;
}

.report-container-print {
  overflow-y: auto;
  padding: 30px;
}

/* Popup styling */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
}

.suggetion-number {
  width: 98px;
  height: 98px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;

}

.strong-disagree {
  background: #F33A0C33;
  color: #F33A0C90;
}


.disagree {
  background: #FF770033;
  color: #FF770090;
}

.neutral {
  background: #FCBE2833;
  color: #FCBE2890;
}

.agree {
  color: #85CD0190;
  background: #85CD0133;
}


.strongly-agree {
  background: #00BF1133;
  color: #00BF1190;

}


.rating-button:hover .strong-disagree {
  background: #F33A0C80;
  color: #F33A0C80;
}


.rating-button:hover .disagree {
  background: #FF770050;
  color: #FF770080;
}

.rating-button:hover .neutral {
  background: #FCBE2850;
  color: #FCBE2880;
}

.rating-button:hover .agree {
  color: #85CD0180;
  background: #85CD0150;
}


.rating-button:hover .strongly-agree {
  background: #00BF1150;
  color: #00BF1180;

}






.rating-button.selected:hover .strong-disagree {
  background: #F33A0C;
  color: #fff;
}


.rating-button.selected:hover .disagree {
  background: #FF7700;
  color: #fff;
}

.rating-button.selected:hover .neutral {
  background: #FCBE28;
  color: #fff;
}

.rating-button.selected:hover .agree {
  color: #fff;
  background: #85CD01;
}


.rating-button.selected:hover .strongly-agree {
  background: #00BF11;
  color: #fff;

}






.selected .strong-disagree {
  background: #F33A0C;
  color: #F33A0C33;
}


.selected .disagree {
  background: #FF7700;
  color: #fff;
}

.selected .neutral {
  background: #FCBE28;
  color: #fff;
}

.selected .agree {
  background: #85CD01;
  color: #fff;

}


.selected .strongly-agree {
  background: #00BF11;
  color: #fff;

}

.selected .suggetion-number {
  color: #fff;
}


.traits1-text {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

/* Loader.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  /* Dark semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupContent,
.successMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  /* White background for better contrast */
  color: #000;
  /* Black text for better readability */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.circle-loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ffc801;
  /* Blue color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.popupText {
  font-size: 16px;
}

.successMessage {
  height: 80vh;
  background: #fff;
  /* Green background for success */
  color: #000;
  /* White text */
  animation: fadeIn 0.5s ease-out;
}

.successText {
  font-size: 18px;
  margin-bottom: 60px;
  margin-top: 44px;
  max-width: 220px;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.okButton {
  color: #000 !important;
  background: #ffae37 !important;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  min-height: 50px;
  display: flex;
  align-items: center;
  width: 175px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  justify-content: center;
  background-color: #ffae37;
  color: white;
  border-radius: 10px;
}

.okButton:hover {
  background-color: #ddd;
}

.wizard-section-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.wizard-section-container div{
  width: 80px;
  display: flex;
  margin: auto;
}

.part1text {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.printButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

/* Animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width:992px) {
  .desktop_background {
    display: none;
  }

  .estimationform {
    width: 100%;
    margin: 40px auto 40px auto;
  }

  .container {
    padding: 20px;
  }

  .items-wrapper {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 20px;
  }

  .input-inner {
    width: 100%;
  }
}

@media (min-width:992px) {
  .mobile_background {
    display: none;
  }
}











.wizard-container {
  margin: auto !important;
  background-color: #fff;
  border-radius: 15px;
}


.personality-test {
  background-color: #0000003b;
  padding: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.error-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  /* Red background for error messages */
  color: #fff;
  /* White text color */
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.error-message-login {

  /* Red background for error messages */
  color: #f44336;
  /* White text color */


}

.rating-buttons {
  display: flex;
  width: 100%;
  gap: 20px;
  max-width: 920px;
  min-height: 400px;
  align-items: center;
}

.traits-text {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;


}

.rating-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 10px;
  gap: 20px;
  width: 100%;
  padding: 20px 30px;
  max-height: 200px;

}

.rating-button.selected {
  border-color: #ffc107;
  box-shadow: 0 0px 10px rgba(0, 0, 0, .2);
}

.part1-radio-group .radio-label {
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.rating-text {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  height: 40px;
}

.part1-radio-group {
  display: flex;
  gap: 5%;
  width: 100%;
  justify-content: center;
  min-height: 400px;
  align-items: center;
}

.part1-radio-group .radio-label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
  width: 100%;
  text-align: center;
  align-items: center;
  border: 2px solid #f3f3f3;
  border-radius: 15px;
  max-width: 280px;
  height: 290px;
  justify-content: center;
}

.radio-label .part1text {
  height: 65px;
}

.main-container-wrapper {
  min-height: 500px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.report-data {
  overflow-x: auto;
  white-space: pre-wrap;
}

.radio-label input[type="radio"]:checked+.radio-label {
  background-color: #e0f7fa;
}

.part1-radio-group .radio-label:hover {
  cursor: pointer;
  border-color: #9e9e9e;
}

.rating-button:hover {
  cursor: pointer;
  transform: scale(1.1);
  background: #f5f5f5;
}

.selected:hover {
  background: #fff;
  transform: scale(1);
}

.rating-button img {
  max-width: 40px;
  width: 100%;
}

.checkbox-label.selectedActive {
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 15px;
  border-radius: 12px;
  border: 2px solid #0E78E5;
}

.radio-label input[type="radio"] {
  /* Hides the radio button */
}

.radio-image {
  cursor: pointer;
  border: 2px solid transparent;
  width: 100px;
  /* Adjust width as needed */
  height: 100px;
  /* Adjust height as needed */
  transition: border-color 0.3s ease;
}

.radio-label input[type="radio"]:checked+.part1text {
  border-color: #007BFF;
  /* Highlight border color when selected */
}


.wizard-container {
  font-family: Arial, sans-serif;
  max-width: 1230px;
  margin: 0 auto auto;
  padding: 50px 20px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.wizard-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 320px;
  justify-content: space-between;
}

.finish-section-title {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0px;
  color: #212121;
  font-weight: 600;
  font-family: 'Inter', sans-serif
}

.genarateVideo {
  width: 100px;
}

.section-title {
  font-size: 22px;
  margin-bottom: 15px;
  color: #616161;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  text-align: center;
}

.question-text2 {
  color: #000 !important;
  font-size: 28px !important;

}

.completion-text {
  font-size: 20px;
  line-height: 24px;
  color: #424242;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  margin-top: 12px;
  margin-bottom: 20px;
}


.popupTitle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;

}

.popupText {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

}


.popupContent {
  padding: 40px 25px;
}

.statement-text {
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: .2px;
  font-style: normal;
  line-height: 34px;
  color: #000;
  font-weight: 500;
  font-size: 24px;
}



.question-text {
  margin: 5px 0px;
  font-family: Roboto, sans-serif;
  letter-spacing: .2px;
  font-style: normal;
  line-height: 34px;
  color: #6c757d;
  font-weight: 500;
  font-size: 22px;

}

.input-field {
  padding: 8px 8px 8px 15px;
  font-size: 20px;
  margin-bottom: 10px;
  border-radius: 16px;
  border: 2px solid #cbcaca;
  height: 70px;
  max-width: 594px;
  width: 100%;
  margin: auto;
}

.input-field::placeholder {
  color: #9e9e9e;
}

.input-field:focus-visible {
  outline-color: #424242;
}


.checkbox-group {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
}

.radio-group label, .checkbox-group label {}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.finish-button:hover {
  background-color: #FF9800 !important;
}

.button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}


.next-button {
  color: #000 !important;
  background: #f6ba22 !important;
  border-radius: 10px;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  min-height: 40px;
  display: flex;
  align-items: center;
  width: 120px;
  height: 37px;
  margin: auto 0 auto auto;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  padding: 10px 20px;
  justify-content: center;
  background-color: #ffae37;
  color: white;
  border-radius: 10px;
  height: 45px;

}

.next-button:hover {
  background-color: #FF9800 !important;
  color: white;
}

.previous-button {
  background-color: #eeeeee;
  color: #424242;
  width: 120px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  height: 45px;
  font-weight: 600;
}

.previous-button:hover {
  background-color: #e0e0e0;
}

.radio-label.checked:hover {
  border-color: #ffc107;
}

.skip-button {
  background-color: #ffc107;
  color: white;
}

.finish-button {
  background-color: #ffae37;
  width: 170px;
  color: #fff;
  padding: 15px;
  margin: auto;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.progress-bar-container {
  display: flex;
  justify-content: center;
}

.progress-bar {
  width: 80%;
  height: 5px;
  -webkit-appearance: none;

}

.progress-bar::-webkit-progress-bar {
  background-color: #f3f3f3;
}

.progress-bar::-webkit-progress-value {
  background-color: #ffc107;
}

.greeting-text {
  text-align: center;
  font-size: 30px;
  line-height: 35px;
  margin: 10px 0px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #000;
}

.progress-text {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #9e9e9e;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
}

.progress-text span {
  color: #424242 !important;
}

.progress-text .completed-steps {
  color: #f48a2b !important;
}


.checkbox-group .traits-label {
  display: flex;
  gap: 10px;
  width: 297px;
  height: 65px;
  padding: 10px 10px 10px 50px;
  align-items: center;
  border: 2px solid #eeeeee;
  border-radius: 12px;
}

.checkbox-group .traits-label:hover {
  border-color: #bdbdbd;
}


.traits-label input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.traits-label2 {
  max-width: 390px !important;
  width: 100% !important;
}

.greeting-text2 {
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 28px;
  margin: 10px 0px;
  text-align: center;
  color: #212121;
  width: 100%;
}

.greeting-text2 span strong {
  color: #000;
  font-size: 18px;
}

.traits-label {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 40px;
  /* Space for custom checkbox */
  cursor: pointer;
  font-size: 16px;
}

.traits-label input[type="checkbox"]:checked+.custom-checkbox {
  border: none;
}

.traits-label input[type="checkbox"]:checked+.custom-checkbox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #FFC107;
  /* Checked background color */
  border-radius: 4px;
  border: 2px solid #FFC107;
  /* Border color */
}

.checkbox-group .traits-label.selected-checked {
  border: 2px solid #FFC107;
  box-shadow: 0 0px 10px rgba(0, 0, 0, .2);

}

.traits-label input[type="checkbox"]:checked+.custom-checkbox::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%) rotate(-35deg);
  width: 10px;
  height: 6px;
  border: solid #000;
  border-width: 0 0 3px 3px;
  /* Checkmark color */
}

.custom-checkbox {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}


.wizard-section textarea {
  width: 100%;
  min-height: 250px;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  padding: 15px 15px;
  margin: auto;
  border-radius: 16px;
  border: 2px solid #cbcaca;
}


.wizard-section textarea::placeholder {
  color: #9e9e9e;
  font-weight: 400;
}

.prev-next-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


.personal-description {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.finish-icon {
  width: 80px;
  display: flex;
  margin: auto;
}

.personality-test-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.background-video-personality {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
}

.text {
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.desktop-video {
  display: block;
}

.mobile-video {
  display: none;
}

.aspirations_edu_advisor_logo {
  width: auto;
  height: 35px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
top: 20px;
}


@media screen and (max-width:767px) {
  .section-title {
    font-size: 20px;
  }

  .desktop-video {
    display: none;
  }

  .mobile-video {
    display: block;
  }

  .greeting-text {
    font-size: 26px;
  }

  .wizard-section {
    justify-content: space-between;
    height: 100%;
  }

  .checkbox-group label input {
    width: auto;
  }

  .part1-radio-group .radio-label:hover {
    transform: scale(1);
  }

  .button-group {
    flex-direction: column;
  }

  .wizard-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

  }

  .part1-radio-group {
    flex-direction: column;
  }

  .part1-radio-group .radio-label {
    padding: 20px;
  }

  .rating-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .rating-button {
    width: 40%;
  }
}

/* CSS for radio button labels */
.radio-label {
  display: block;
  cursor: pointer;
  padding: 5px;
  margin: 5px 0;
}

.radio-label input[type="radio"] {}

.radio-label .radio-text {
  margin-left: 5px;
}

/* CSS for selectedActive state */
.radio-label.selectedActive {
  background-color: #fff;
  border-radius: 5px;
  padding: 0px 15px;
  border-radius: 12px;
  border: 2px solid #0E78E5
}

/* Additional styles */
.radio-label input[type="radio"]:checked+.radio-text {
  font-weight: bold;
}













.wizard-wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}


.wizard-assement-question-step1 .radio-label input {
  display: block;

}

.wizard-assement-question-step1 .radio-label {
  display: flex;
  align-items: center;
}


/* Base Styles */
.wizard-section {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 8px;
}

.assesment-top {}

.empathetic-tip, .interactive-reminder, .instant-tip, .motivational-popup {
  padding: 10px;
  border-radius: 12px;
  background: #E7F2FD;

}

.empathetic-tip p, .interactive-reminder p, .instant-tip p, .motivational-popup p {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #003A76;

}

.assesment-top h2 {
  font-size: 28px;
  color: #000;
  margin-bottom: 10px;
  text-align: center;
}

.assesment-top p {
  color: #555;
  text-align: center;
  font-family: 'Inter', sans-serif;

  text-align: center;

}

.wizard-assement-question {
  margin-bottom: 25px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.wizard-question {
  margin-bottom: 25px;
  background: #fff;
  border-radius: 8px;
}

.wizard-question label {
  display: flex;
  margin-bottom: 10px;
}

.wizard-assement-question label {
  display: block;
  margin-bottom: 10px;
}

input[type="radio"] {}

.checkbox-label {
  gap: 10px;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
  cursor: pointer;
  height: 45px;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  padding: 0 15px;
  background-color: #E0E0E0;
}

.radio-label {
  gap: 10px;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
  cursor: pointer;
  height: 45px;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  padding: 0 15px;
  background-color: #F5F5F5;
}

input[type="number"] {
  width: auto;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.radio-text {
  font-size: 16px;
}

.selectedActive {
  font-weight: bold;
}

p {
  font-size: 18px;
  color: #666;
  margin-top: 5px;
}

input[type="range"] {
  width: 100%;
  margin: 10px 0;
  -webkit-appearance: none;
  background: #e8ebf1;
  /* Light background */
  height: 8px;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #3b9ce8;
  /* Primary color */
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffffff;
}

/* 888888888888888888888 */
.knwizard-question {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;

}

.knwizard-input {
  background: #F5F5F5;
}


.assesment-top h2 {
  font-family: 'Inter', sans-serif;
  line-height: 21.47px;
  text-align: center;
}

.next-start-button {
  display: flex;
  margin: auto;
  width: 165px;
  height: 50px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  text-align: center;
  color: #FFFFFF;
  background: #0E78E5;
}

.wizard {
  height: 100%;
  overflow: auto;

}

.dropdown {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  margin-top: 5px;
  width: 200px;
  text-align: center;
}


/* Styles for the full-screen popup */
.fullscreen-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}



/* Styles for the popup content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 80%;
  width: 80%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for the close button */
.close-button {
  align-self: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Styles for individual dropdown options */
.popup-content div {
  padding: 10px;
  margin: 5px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Hover effect for dropdown options */
.popup-content div:hover {
  background-color: #f1f1f1;
}

.wizard-assement-question-step1 .dropdown {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  background: #F5F5F5;
  font-family: 'Inter', sans-serif;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-height {
  display: flex;
  gap: 10px;
}

.instant-tip-step1 p span {
  font-family: 'Inter', sans-serif;
  text-align: center;
  color: #0068D2;
}

.instant-tip-step1 p {
  font-family: 'Inter', sans-serif;
  text-align: center;
  color: #004286;
}

.steptype {
  font-family: 'Inter', sans-serif;
  color: #424242;
}


.kngreeting {

  border-radius: 20px;
  font-family: 'Inter', sans-serif;
  color: #0E78E5;
  background: #0E78E51A;
  display: flex;
  align-items: center;
  margin: 0px auto 100px;

}



/* Responsive Styles */
@media (max-width: 768px) {
  .next-start-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  /* Styles for the dropdown button */
  .dropdown {
    width: 200px;
  }

  .wizard-assement-question-step1 .dropdown {
    max-width: 349px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.78px;
  }

  .wizard-assement-question-step1 .dropdown img {
    max-width: 14px;
    height: 7px;

  }

  .dropdown-height {
    display: flex;
    gap: 10px;
  }

  .instant-tip-step1 p span {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .instant-tip-step1 p {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;

  }



  .prev-next-container div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .steptype {
    font-size: 15px;
    font-weight: 500;
    line-height: 20.13px;
    text-align: center;
  }



  .assesment-top h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 21.47px;


  }


  .kngreeting {
    width: 322px;
    height: 104px;

    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;


  }


  .wizard-container-start {
    justify-content: space-between;
    height: 100%;
  }

  .nwizard-question {
    font-size: 16px;
  }

  .knwizard-banner {
    padding: 0px 0px;
  }

  .knwizard-banner img {
    width: 100%;
  }

  .kn-wizard-logo {
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .kn-wizard-logo img {
    display: flex;
    max-width: 220px;
    width: 100%;
  }

  .wizard-section {}

  .assesment-top h2 {
    font-size: 20px;
  }

  .assesment-top p {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
  }

  .wizard-question {
    margin-bottom: 15px;
    width: 100%;
  }

  .radio-label {
    font-size: 14px;
  }

  .radio-text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .wizard-section {}

  .assesment-top h2 {
    font-size: 16px;
  }

  .assesment-top p {
    font-size: 15px;
  }

  .wizard-question {
    margin-bottom: 10px;
  }

  .radio-label {
    font-size: 14px;
  }

  .radio-text {
    font-size: 14px;
  }
}