#root, .left-column {
    display: flex;
    height: 100%
}

body {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.main-three-colum-layout {
    display: flex;
    padding: 0;
    background-size: cover;
    margin: auto;
    justify-content: center
}

#root {
    max-width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    align-items: end
}

.start-root .main-three-colum-layout-wrapper {
    max-height: calc(100vh)
}

.output-area {
    max-height: calc(100vh - 182px)
}

@media (min-width:768px) {
    #root {
        display: flex;
        align-items: end
    }

    .start-root .main-three-colum-layout-wrapper {
        max-height: calc(100vh)
    }

    .output-area {
        max-height: calc(100vh - 182px);
        min-height: calc(100vh - 182px);
        display: flex;
        flex-direction: column-reverse
    }

    .start-input-container .input-container-wrapper {
        margin-bottom: 0
    }

    .center-column {
        max-width: 1300px
    }
}

.left-column {
    width: 100%;
    flex: auto;
    justify-content: center;
    align-items: center;
    position: relative
}

.left-column .video-wrapper {
    width: 100%;
    display: inline-block;
    position: relative;
    height: 100%;
    overflow: hidden
}

.left-column .video-wrapper .new-background-video {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 80px;
    left: 0;
    border-radius: 12px;
    overflow: hidden
}

.left-column .logo-wrapper {
    position: absolute;
    top: 10vh;
    width: 100% !important;
    z-index: 999
}

.left-column .logo-wrapper a {
    width: 100% !important;
    text-align: center;
    margin: 0 auto;
    display: inline-block
}

.left-column .logo-wrapper a img {
    width: 240px !important;
    margin: 0 auto
}

.main-three-colum-layout .center-column {
    position: absolute;
    z-index: 10;
    justify-content: flex-end
}

.right-column {
    position: fixed !important;
    right: 12px !important;
    left: auto !important;
    top: 0;
    z-index: 999;
    width: 40px !important;
    display: flex;
    align-content: center;
    align-items: center
}

.main-three-colum-layout .navbar-items {
    max-width: 1400px;
    margin: auto;
    flex-direction: row
}

.main-three-colum-layout .navbar {
    background: 0 0 !important;
    max-height: 95vh
}

.right-column .navbar .navbar-items li {
    width: 30%;
    height: 35px;
    border-radius: 18px;
    margin: 0 0 7px
}

.main-inner-container {
    width: calc(100% - 60px)
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    position: relative
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
    margin: 0
}

.bot-msg-bubble .play-button-container {
    margin-top: 1px;
    min-width: 38px
}

.bot-msg-bubble .play-button-container button {
    background: 0 0 !important;
    border: none !important;
    display: flex;
    text-align: center;
    cursor: pointer;
    border-radius: 15%;
    padding: 0;
    width: 90px;
    height: 34px
}

.bot-msg-bubble .play-button-container button::after {
    border-radius: 100%;
    height: 34px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 34px
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0
    }
}

.bot-msg-bubble .play-button-container button img {
    border-radius: 15%;
    height: 34px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 90px;
    background: 0 0;
    transition: opacity .3s, box-shadow .3s;
    opacity: .95
}

.bot-msg-bubble .play-button-container button img:hover {
    opacity: 1
}

.main-background-container .main-inner-container .kps-chat-form .input-container .chat-logo-image {
    max-width: 50px;
    border-radius: 25px
}

.new-mobile-background-video {
    display: none
}

@media (min-width:3000px) {
    #root {
        max-width: 2600px
    }

    .left-column .video-wrapper, .main-background-container .main-inner-container, .main-three-colum-layout .navbar {
        max-height: 1200px
    }

    .left-column .logo-wrapper {
        top: 25vh
    }
}

@media (min-width:1920px) {
    .main-three-colum-layout .center-column {
        width: 70%
    }
}

@media (min-width:992px) {
    .main-background-container .main-inner-container .kps-chat-form .input-container {
        margin: 0
    }

    .start-main-background-container {
        position: unset
    }

    .main-background-container .main-inner-container .start-form {
        position: relative;
        max-width: 100% !important;
        bottom: 20px;
        margin: 0
    }
}

@media (max-width:1150px) and (min-width:992px) {
    .main-three-colum-layout {
        padding: 0
    }

    .central-greeting-wrapper, .start-main-background-container {
        max-width: 550px
    }

    .main-background-container {
        width: 85%
    }

    .desk_home_video_container {
        width: 86%
    }

    .main-three-colum-layout .center-column {
        width: 100%
    }

    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0;
        bottom: auto;
        left: auto !important;
        display: none;
        align-items: center;
        z-index: 999
    }

    .left-column .logo-wrapper {
        top: 12vh
    }

    .left-column .video-wrapper .new-background-video {
        top: 130px
    }
}

@media only screen and (min-width:1024px) and (max-height:1366px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio:1.5) {
    .left-column .logo-wrapper {
        top: 26vh
    }
}

@media (max-width:995px) and (min-width:768px) {
    .profile-wrapper {
        left: -165px
    }

    .central-column-bbtn-wrapper {
        top: 69px
    }

    .central-navbar-backside-color {
        width: 100%;
        height: 70px;
        top: 0;
        background: red;
        position: absolute
    }

    .main-three-colum-layout .center-column {
        width: 100% !important;
        margin: 0 auto !important;
        padding-right: 0;
        max-width: 100%;
        overflow-y: auto;
        justify-content: flex-start
    }

    .main-background-container .main-inner-container {
        max-height: 100vh;
        border-radius: 0
    }

    .main-background-container .main-inner-container .kps-chat-form {
        position: relative;
        max-width: 100% !important;
        bottom: 48px;
        margin: 0;
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    .main-three-colum-layout {
        max-width: 100vw
    }

    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important
    }

    .main-three-colum-layout .start-right-column {
        background-color: transparent !important
    }

    .main-three-colum-layout .right-column .mobile-navbar-items, .main-three-colum-layout .right-column .navbar {
        width: 100% !important
    }

    .main-three-colum-layout .mobile-navbar-items li.logo-li {
        width: auto !important
    }

    .mobile-navbar-items li {
        width: 37px !important;
        height: 37px !important;
        border-radius: 8px;
        background: rgba(0, 0, 0, .5);
        margin: 0 auto 14px
    }

    .mobile-navbar-items li.logo-li {
        width: auto !important;
        background: 0 0 !important;
        padding: 0 !important
    }

    .main-three-colum-layout .mobile-navbar-items li a {
        display: flex;
        text-align: center;
        align-items: center
    }

    .main-three-colum-layout .mobile-navbar-items li a img {
        height: auto;
        width: auto !important;
        margin: 0 auto
    }

    .main-three-colum-layout .mobile-navbar-items .menu-kps {
        width: 37px !important
    }

    .main-three-colum-layout .mobile-navbar-items li a img.logo-kps {
        max-width: 200px !important
    }

    .main-three-colum-layout .mobile-navbar-items li {
        margin: 15px 0;
        padding: 0
    }

    .new-mobile-background-video {
        display: block;
        width: 120%;
        height: auto;
        position: fixed;
        top: 50%;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, -50%)
    }
}

@media (min-width:767px){
    .delay_greeting.show_greeting {
        margin-right: 20px!important;
    }
}

@media (max-width:767px) {
    .main-three-colum-layout .center-column {
        overflow-y: auto;
        right: 0
    }

    .output-area {
        padding: 20px 0PX 0PX;
        border-radius: 16px 16px 0 0;
        margin-bottom: 0;
        box-shadow: none
    }

    .main-inner-container {
        background: 0 0 !important;
        box-shadow: none
    }

    .main-three-colum-layout .right-column {
        position: absolute !important;
        width: 100% !important;
        right: 0 !important;
        background-color: #fff !important;
        border-radius: 0 0 20px 20px;
        top: 0;
        z-index: 100000;
        box-shadow: 0 1px 1px 0 hsla(0, 0%, 0%, .12), 0 2px 11px -1px hsla(0, 0%, 0%, .02), 0 1px 8px 0 hsla(0, 0%, 0%, .12)
    }

    .start-mobile-navbar-items .logo-li {
        margin-left: 14px !important
    }

    .main-three-colum-layout .start-right-column {
        background-color: transparent !important
    }

    .main-three-colum-layout {
        max-width: 100vw
    }

    .main-three-colum-layout .right-column .mobile-navbar-items, .main-three-colum-layout .right-column .navbar, .right-column {
        width: 100% !important
    }

    .main-three-colum-layout .mobile-navbar-items li.logo-li {
        width: auto !important
    }

    .mobile-navbar-items li {
        width: 40px !important;
        height: 35px !important;
        border-radius: 25px;
        margin: 0 auto 14px
    }

    .mobile-navbar-items li.logo-li {
        width: auto !important;
        background: 0 0 !important;
        padding-left: 0 !important
    }

    .main-three-colum-layout .mobile-navbar-items li a {
        display: flex;
        text-align: center;
        align-items: center
    }

    .main-three-colum-layout .mobile-navbar-items li a img {
        height: auto;
        width: auto !important;
        margin: 0 auto
    }

    .main-three-colum-layout .mobile-navbar-items li a img.menu-kps {
        width: 30px !important;
        opacity: .6
    }

    .main-three-colum-layout .mobile-navbar-items li a .menu-kps:hover {
        width: 30px !important;
        opacity: 1
    }

    .main-three-colum-layout .mobile-navbar-items li a img.logo-kps {
        max-width: 190px !important
    }

    .main-three-colum-layout .mobile-navbar-items li {
        padding: 0;
        margin: 8px 0
    }

    .main-three-colum-layout .center-column {
        width: 100%;
        margin: 0 !important;
        padding-right: 0;
        max-width: 100%;
        justify-content: flex-start;
        padding-bottom: 10px;
        padding-top: 70px
    }

    .start-central-column {
        padding-bottom: 20px
    }

    .main-background-container .main-inner-container .kps-chat-form .mobile-voice-container {
        position: relative;
        background-color: #dbe0e8;
        display: none
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container {
        background-color: transparent !important;
        border-radius: 0 0 12px 12px;
        width: 100% !important;
        padding-bottom: 1px
    }

    .new-mobile-background-video {
        display: block;
        width: auto;
        height: 102%;
        position: fixed;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }
}

.voice-btn-kps .ripple-btn img {
    border-radius: 24px;
    flex-shrink: 0
}

.start-grid-center-column {
    width: 100% !important;
    max-width: 1212px !important
}