.chat-type-container {
    width: 100%;
    display: flex;
    margin: auto;
}

.chat-type-inner {
    width: 100%;
}

.chat-type-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-type-items h3{
    margin: 0px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    font-weight: 500;
    line-height: 55px;
}

.chat-type-items p{
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 80px;
}

.chat-type-item-wrapper {
    width: 100%;
}

.chat-type-item {
    padding: 13.5px 15px 13.5px 20px;
    display: flex;
    background: #FFFFFFCC;
    gap: 20px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
}

.chat-type-item:hover {
    background: #FFFFFF;
}

.chat-type-item img {
    width: 30px;
}

.chat-type-item-img {
    padding: 9px 9px 4px;
    background: #FFC60133;
    border-radius: 50px;
}

.chat-type-item p {
    margin: 0px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}


.option-wrapper {
    width: 100%;
}

.option-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.options {}

.options1, .options2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding-top: 20px;
}

.options2 {}

.option-item {}

.options-item img {
    max-width: 46px;
    min-width: 45px;
    width: 100%;
}

.options-item2 img {
    max-width: 100px;
    min-width:100px;
    width: 100%;
}

.options-item2-img  {
    max-width: 100px;
    min-width:100px;
    width: 100%;
}

.options-item:hover .options-item img {
    filter: brightness(110%);
    transform: scale(1.03);
}



.options-item .option-text {}

.options-item {
    height: 135px;
    align-items: center;
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    background: #ffffffe6;
    border: 0 solid #0000;
    border-radius: 26px;
    box-shadow: inset 0 -6px 5px -1px #0000001f, inset 0 0 1px -4px #0000001f;
    cursor: pointer;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    max-width: 135px;
    min-height: 125px;
    min-width: 80px;
    padding: 10px;
    width: 100%;

}

.options-item2 {
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: #ffffffb3;
   border: 2px solid transparent;
    border-radius: 30px;
    /* box-shadow: inset 0 -6px 5px -1px #0000001f, inset 0 0 1px -4px #0000001f; */
    cursor: pointer;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    min-height: 75px;
    min-width: 80px;
    padding: 60px 40px;
    width: 100%;

}

.options-item:hover {
    transform: scale(1.1);
    background: #fff !important;
}

.options-item2:hover {
    transform: scale(1.01);
    background: #ffffffee !important;
}

.option-text p {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 0;
}

.menu-logo-kps-desktop{

}

@media screen and (min-width: 767px) {

    .option-text p {
        font-size: 22px;
        line-height: 28px;
    }
}

@media (max-width:767px) {
    .chat-type-items h3{
        font-size: 30px;
        line-height: 40px;
    }
    
    .chat-type-items p{
        font-size: 24px;
        line-height: 38px;
    }

    .options-item2 img {
        max-width: 70px;
        min-width: 70px;
    }

    .options-item2 {
        justify-content: center;
        flex-direction: column;
        padding: 40px 15px;
    }

    .chat-type-items p {
        margin-bottom: 30px;
    }

    .option-text p {
        font-size: 16px;
    }
}

@media (max-width:992px) {
    .chat-type-item p {
        font-size: 15px;
        line-height: 20px;
    }

    .chat-type-item {
        padding: 12px 15px 12px 15px;
        gap: 12px;
    }

    .chat-type-wrapper {
        margin: auto;
    }

    .options-item {
        max-height: 110px;
        height: 100%;
        align-items: center;
        -webkit-backdrop-filter: blur(2.5px);
        backdrop-filter: blur(2.5px);
        background: #ffffffe6;
        border: 0 solid #0000;
        border-radius: 15px;
        box-shadow: inset 0 -6px 5px -1px #0000001f, inset 0 0 1px -4px #0000001f;
        cursor: pointer;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        max-width: 135px;
        min-height: 108px;
        min-width: 74px;
        padding: 10px;
        width: 100%;
    }

    .chat-type-wrapper {
        width: 100%;
    }

    .options1, .options2 {
        padding-top: 10px;
    }

}