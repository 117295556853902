.app, .new-background-video-container:hover .thumbnail-overlay {
    opacity: 0
}

.nav_desktop_tbtn {
    position: fixed;
    z-index: 10000;
    left: 4px;
}

.desk_home_bg_video_wrapper, .mobile_background_video {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end
}

.desk_home_bg_video_container {
    width: 100%;
    position: absolute;
    height: 100%
}

.new-background-bg-video-container-chat-video, .start_central-greeting-wrapper-mobile {
    display: flex
}

.central-greeting-down-arrow {
    bottom: 0;
    animation: 1.5s cubic-bezier(.68, -.55, .27, 1) forwards loadingArrow
}

.app {
    position: relative;
    animation: 1s cubic-bezier(.68, -.55, .27, 1) both bubbleAnimation;
    transform-origin: bottom center;
    will-change: transform;
    perspective: 800px
}

.app.loaded {
    opacity: 1;
    top: 15px
}

@keyframes bubbleAnimation {
    0% {
        transform: scale(0) translate3d(0, 0, 0);
        opacity: 0;
        bottom: -70px;
        filter: blur(0px)
    }

    100% {
        transform: scale(1) translate3d(0, 0, 0);
        opacity: 1;
        bottom: -10px;
        filter: blur(0);
        z-index: 100
    }
}

.thumbnail-overlay svg {
    height: 307px;
    width: 320px
}

.thumbnail-overlay {
    position: absolute;
    top: 72px;
    left: 0;
    width: 319px;
    height: 340px;
    z-index: -10;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .7;
    transition: opacity .3s
}

.mobile_1v3_side_nav, .mobile_1v3_side_nav_open {
    transition: left .5s cubic-bezier(.25, .46, .45, .94)
}

.thumbnail-overlay img {
    max-width: 100%;
    max-height: 100%
}

.central-greeting-wrapper-mobile , .start-central-navbar-backside-color, .start-central-video-display, .start-menu-logo-kps-wrapper, .start-mobile-text-hide, .start-nav-img {
    display: none
}



.central-greeting-wrapper {
    position: relative;
    width: 100%;
    max-width: 610px;
    display: none;
    min-height: 126px
}

.desk_home_video_wrapper {
    position: relative;
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: flex-end
}

.desk_home_video_container, .mobile_1v3_side_nav_wrapper {
    height: 100%
}

.start-central-greeting-wrapper .central-greeting-container-wrapper {
    display: flex;
    width: 100%;
    justify-content: center
}

.start-central-greeting-wrapper {
    display: flex !important
}

.central-greeting-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 35px;
    width: 100%;
    background: #fff;
    stroke-width: 1px;
    stroke: #D4E3F9;
    filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, .08));
    padding: 10px 10px 14px;
    position: absolute;
    z-index: 10;
    gap: 2px;
    max-width: 495px;
    min-height: 125px
}

.central-greeting-text, .central-greeting-user-name {
    color: #1a3054;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: normal
}

.central-greeting-user-name {
    font-weight: 700
}

.central-greeting-text {
    font-weight: 500;
    margin-top: 8px
}

.central-greeting-user img {
    padding-bottom: 1px
}

.central-greeting-img {
    width: 74px
}

.central-greeting-question {
    display: flex;
    align-items: center;
    gap: 10px
}

.central-column-btn-right {
    width: 130px;
    height: 30px
}

.central-greeting-ques {
    color: #0a2b72;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 33px
}

.central-greeting-question img {
    width: 22px
}

.central-greeting-down-arrow {
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #fff;
    margin: 0 auto;
    position: absolute;
    left: -5px;
    transform: rotate(40deg);
    z-index: 100
}

.new-background-video-container {
    position: relative;
    width: 100vw;
    min-height: 380px;
    height: 41vh;
    background: 0 0 !important;
    border-radius: 16px 0 0
}

.desk_home_video_container, .right_side_nav {
    width: 100%
}

.new-background-video-container-chat-video {
    width: 100%;
    object-fit: cover;
    max-height: 700px;
    border-radius: 25px
}

.new-background-video-container-chat {
    width: 100%;
    height: 100%;
    background: 0 0 !important;
    border-radius: 16px 0 0;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center
}

.left-column .video-wrapper .new-background-video-container-chat video {
    height: 100%;
    top: auto
}



.main-three-colum-layout .mobile-navbar-items li a .user-kps {
    width: 110% !important
}

.button-login{
    max-width: 52px;
}

.button-login img{
    max-width: 40px;
}

.main-three-colum-layout .mobile-navbar-items .user-li {
    border-radius: 16px !important
}

.main-three-colum-layout .mobile-navbar-items .menu-li {
    background: 0 0
}

.main-three-colum-layout .mobile-navbar-items li a .menu-icon {
    width: 90% !important;
    margin-left: 0
}

.start-menu-li {
    border-radius: 25px !important
}

.start-central-column {
    max-width: 1300px !important;
    border-radius: 12px !important;
    display: flex !important;
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    background: 0 0;
    flex-direction: row;
    padding-right: 18px;
    height: 100%;
    padding-bottom: calc(100vh - 750px)
}

.main-three-colum-layout-container {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center
}

.main-three-colum-layout .start-right-column {
    position: fixed !important;
    background: #e4efff !important
}

.start-cental-video-wrapper {
    position: relative;
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    z-index: 0
}

.start-central-logo {
    margin: -18px 0 10px;
    position: relative;
    z-index: 10000
}

.start-video {
    width: 780px !important;
    margin-right: 0;
    margin-top: 50px;
    height: 438px
}

.start-mobile-text {
    color: #1e56a6;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0
}

.start-main-three-colum-layout {
    align-items: center;
    justify-content: center !important
}

.start-mobile-text-wrapper {
    display: block !important;
    position: relative;
    z-index: 0
}

.start-mobile-navbar-items, .start-nav-li {
    background: 0 0 !important
}

.center-column {
    display: flex;
    padding-right: 18px
}

.start-main-inner-container {
    box-shadow: none !important
}

.greeting_form_wrapper {
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: flex-end
}

.mobile_1v3_nav {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    width: 100%
}

.toggle_menu_wrapper:hover {
    /* background: #0000001a */
}

.toggle_menu_wrapper {
    width: 48px;
    padding: 10px;
    background: transparent;
    border-radius: 15px;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer
}

.toggle_menu_desk {
    width: 30px;
    height: 30px;
    transform: scaleX(-1)
}

.mobile_1v3_nav_wrapper {
    padding: 12px 12px 5px;
    width: 100%;
    backdrop-filter: blur(5px) brightness(100%);
    -webkit-backdrop-filter: blur(2px) brightness(100%);
}

.mobile_1v3_nav_profile, .mobile_1v3_nav_profile img {
    width: 40px
}

.desk_home_video_profile {
    position: absolute;
    height: 100%
}

.mobile_1v3_side_nav {
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 1000;
    width: 100%;
    left: calc(-100vw)
}

.side_nav_menu_item .sharebtn-wrapper {
    display: flex;
    justify-content: unset
}

.mobile_1v3_side_nav_open {
    left: 0
}

.mobile_1v3_side_nav_container {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: space-between
}

.sharebtn-wrapper, .side_nav_menu_item_text, .vertical-button {
    display: flex;
    justify-content: center
}

.mobile_1v3_side_nav_profile a img {
    width: 70px;
    padding: 10px
}

.side_nav_menu_container {
    width: 82vw;
    border-radius: 0 20px 20px 0;
    background: #351D00B2;
    -webkit-backdrop-filter: blur(2px) brightness(100%);
    backdrop-filter: blur(10px) brightness(100%)
}

.side_nav_menu_item_back a img {
    padding-top: 24px;
    max-width: 75px;
    width: 32px
}

.side_nav_menu_item_wrapper .side_nav_menu_item {
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border-radius: 10px
}

.side_nav_menu_item {
    display: flex;
    gap: 20px
}

.side_nav_menu_item:hover {
    background: #00000015
}

.side_nav_menu_item_text {
    align-items: center;
    font-family: Rubik, sans-serif;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align: left
}

.side_nav_menu_item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    justify-content: space-between
}

.side_nav_menu_item_bottom_text {
    text-align: center;
    padding: 5px;
    align-items: center;
    display: flex;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #00000052;
    justify-content: center;
    margin-bottom: 5px
}

.side_nav_menu_item_container {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.side_nav_menu_item_back {
    display: flex;
    justify-content: flex-end;
    margin-left: 5%;
    margin-right: 5%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    gap: 20px
}

.side_nav_menu_item_back a {
    justify-content: flex-end
}

.side_nav_menu_item a {
    display: flex;
    gap: 20px;
    text-decoration: none;
    width: 100%
}

.left-column-logo a {
    display: flex;
    align-items: center
}

.mobile_1v3_nav_toggle .toggle_menu_wrapper img {
    width: 32px;
    transform: scaleX(-1);
    display: none;
}

.mobile_1v3_nav_container {
    display: flex;
    justify-content: space-between;
    width: 100%
}

.mobile_1v3_nav_profile a img {
    max-width: 40px
}

.deskNav {
    width: 0px !important;
    transition: width .6s cubic-bezier(.25, .46, .45, .94);
    left: -65px!important;
}

.left-column-logo {
    position: absolute;
    z-index: 100000;
    top: 18px;
    display: flex;
    gap: 55px;
    height: 30px
}

.left-column-vertical-buttons {
    width: 250px;
    background: #351D00B2;
    position: relative;
    z-index: 10000;
    left: 0px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    transition: width .6s cubic-bezier(0.4, 0.0, 0.2, 1);

}

.button-cmn{
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button-selected-type{
    padding: 2px 16px;
    background: #ffffff98;
    height: 44px;
    width: 155px;
    border-radius: 20px;
    margin-top: 15px;
    color:#000;
    font-size: 16px;
    cursor: pointer;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
font-family: 'Inter', sans-serif;
font-weight: 500;
justify-content: center;
}

.button-selected-type img{
width: 25px;
margin-right: 5px;
}
.button-selected-type:hover{
    background: #ffffffef;
   

}
.video-wrapper {}

.vertical-button {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    justify-content: flex-start;
}

.vertical-button:hover {
    background: #211200CC;
}

.vertical-button-img {
    max-width: 50px;
    width: 30px;
    display: inline-block;
    position: relative
}

.vertical-button:hover .vertical-button-img {
    z-index: 2;
    transform: scale(1.1);
    cursor: pointer;
}

.sharebtn .sharebtn-img {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: none;
    border-radius: 10px;
    background: 0 0;
    padding-left: 0;
}

.sharebtn .sharebtn-img:hover {
    opacity: 1
}

.share {
    cursor: pointer
}

.vertical-button {
    cursor: pointer;

}

.sharebtn-inner {
    display: flex;
    position: relative;
    justify-content: center
}

.side_nav_menu_item button {
    padding: 0 !important
}

.vertical-button a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 30px;
}

.side_nav_text {
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    letter-spacing: 0.3px;
    display: flex;
    margin: auto;
    opacity: 1;
    transform: scale(1);
    transform-origin: left;
    transition: opacity 0.6s ease, transform 0.6s ease;
}

.central-greeting-wrapper-desktop, .central-greeting-wrapper-mobile {
    width: 100%;
    display: flex;
    justify-content: center
}

@media (max-aspect-ratio:177/100) {
    .video_wrapper_dp {
        height: 100% !important
    }
}

@media (min-aspect-ratio:178/100) {
    .video_wrapper_dp {
        height: auto !important
    }
}

.right_side_nav_inner {
    width: 100%;
    display: flex;
    justify-content: end;
    cursor: pointer;
    gap: 10px;   
}

@media (min-width:767px) {
    .left-column-logo {
        width: 100%;
        max-width: 1300px
    }

    .desk_home_bg_video_wrapper {
        display: block
    }

    .mobile_background_video {
        display: none !important
    }

    .start-central-column, .start-main-three-colum-layout {
        min-height: 600px
    }



    @keyframes loadingArrow {
        0% {
            bottom: 0
        }

        100% {
            bottom: -4px
        }
    }
}

.main-three-colum-layout .menu-logo-kps {
    left: -200px;
    max-width: 600px !important;
    padding-top: 0;
    position: absolute;
    top: -235px;
    z-index: 1000;
}

.deskNav .vertical-button .side_nav_text {
    /* display: none; */
    width: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: right;
    transition: opacity 0.4s ease, transform 0.8s ease;
}

.deskNav .vertical-button {
    width: unset;
}

.deskNav .vertical-button a {
    gap: 0;
}

.deskNav .vertical-button .sharebtn .sharebtn-img {
    gap: 0;
    padding-left: 0;
    padding-right: unset;
}

.vertical-button-wrapper {
    width: 100%;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 55px;
}

@media (max-width:767px) {
    .right_side_nav_inner {
      
        justify-content: center;
    }
    .toggle_menu_wrapper {
        /* background: #351D00CC; */
    }

    .video_wrapper_dp {
        height: 110% !important
    }

    .desktop_background_video {
        display: none
    }

    .mobile_background_video {
        display: block;
        object-position: 1% 23%;
        background-position-y: center
    }

    .start-central-column {
        flex-direction: column
    }

    @keyframes loadingArrow {
        0% {
            bottom: 0
        }

        100% {
            bottom: -14px
        }
    }

    .central-greeting-ques {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left
    }

    .new-background-video-container {
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 355px
    }

    .central-greeting-container {
        min-height: 74px;
        border-radius: 25px
    }

    .central-greeting-wrapper {
        max-width: 350px;
        top: 10px !important;
        min-height: 74px
    }

    .central-greeting-text {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;
        text-align: left;
        margin-top: 5px
    }

    .start-central-column {
        align-items: center
    }

    .main-three-colum-layout .menu-logo-kps {
        left: 2px;
        max-width: 220px !important;
        top: 2px;
        width: 400px !important;
    }

    .mobile_1v3_nav_logo {
        display: flex;
        align-items: center
    }

    .vertical-button-img {
        max-width: 35px
    }

    .side_nav_menu_item .sharebtn-wrapper .sharebtn-inner {
        margin-left: 0
    }

    .knightingale_avatar_bar_wrapper {
        height: 60px
    }

    .main-inner-container {
        background: #e4eFFF80 !important;
        box-shadow: none
    }

    .start-main-background-container {
        height: 320px !important;
        position: relative
    }

    .start-mobile-text-wrapper {
        margin-bottom: 15px
    }

    .start-main-logo {
        width: 250px
    }

    .central-navbar-backside-color {
        width: 100%;
        height: 56px;
        top: 0;
        background: red;
        position: absolute
    }

    .greeting_form_wrapper {
        width: 100%
    }
}

@media screen and (min-width:767px) and (max-width:992px) {
    .main-three-colum-layout .menu-logo-kps {
        left: 0px;
        top: 0px;
        max-width: 320px !important;
    }

    .toggle_menu_wrapper {
        background: #351D00CC;
        display: none;
    }

    #yourVideoDivId video, .start-central-video-display .video-wrapper .new-background-video-container video {
        max-width: 500px !important
    }

    .greeting_form_wrapper {
        width: 100%
    }

    .new-background-video-container {
        display: flex;
        justify-content: center
    }

    #yourVideoDivId video {
        object-fit: cover;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100vw !important;
        top: -20px !important;
        height: unset
    }
}

@media (max-width:992px) {
    .central-greeting-img {
        width: 52px
    }

    .desk_home_video_wrapper {
        display: none
    }

    .central-greeting-down-arrow {
        left: auto;
        transform: rotate(0)
    }
}

@media (max-width:995px) {
    .central-greeting-wrapper-desktop {
        display: none
    }
}

@media (min-width:992px) {

    .mobile_1v3_side_nav_open {
        display: none
    }

    .desk_home_video_wrapper, .mobile_1v3_nav {
        display: none
    }

    .central-greeting-img {
        width: 85px
    }

    .app.loaded {
        top: -80px
    }

    .central-greeting-wrapper-mobile, .mobile-navbar-items, .start-central-video-display {
        display: none !important
    }

    .right-column {
        border-radius: 0 !important
    }

    .main-three-colum-layout .right-column {
        position: fixed !important;
        width: 100% !important;
        right: 0 !important;
        flex: 0 0 auto;
        top: 0;
        bottom: auto !important;
        left: auto !important;
        display: flex;
        align-items: center;
        z-index: 999
    }
}

.user-login-img {
    margin: auto 0 !important
}

.main-three-colum-layout .navbar-items .user-login-img a img {
    width: 35px
}

.right-column .navbar .navbar-items .menu-logo-kps-wrapper {
    height: 100% !important;
    padding: 0 !important;
    background: #fff !important;
    margin: 10px 0 5px !important
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden
}

.bold-text {
    font-weight: 800
}

.right-column .navbar .navbar-items .start-menu-logo-wrapper {
    margin-left: 0 !important
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .main-three-colum-layout .navbar-items {
        max-width: 1200px
    }
}

@media screen and (min-width:1280px) {
    .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions, .output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
        max-width: 70ch !important
    }
}

.bacground-client-msg-bubble {
    margin-bottom: 0 !important
}

.knightingale_avatar_bar img {
    width: 100%;
    position: relative;
    bottom: -4px;
    max-width: 55px
}

.knightingale_avatar_bar_wrapper {
    height: 77px
}

.knightingale_avatar_bar {
    height: 55px;
    display: flex;
    justify-content: left
}

.cookie_bars {
    background: #f1e8c3;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center
}

.cookie_bars button {
    background: #ffbd05;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 7px
}

.cookie_bars p {
    margin: 8px;
    font-size: 11px
}

.showCookieMessage {
    height: calc(100% - 0px)
}

.hideCookieMessage {
    height: calc(100%)
}

@media screen and (min-width:767px) {
    .knightingale_avatar_bar {
        display: none
    }

    .cookie_bars_mobile {
        display: none;
    }
}

@media (max-width:767px) {
    .knightingale_avatar_bar_wrapper {
        height: 60px
    }
}