@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Klee+One:wght@400;600&family=Delius&family=Shantell+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);

.main-background-container.background-image, body {
    background-size: cover;
    background-repeat: no-repeat
}

.kps-form-group .kps-login-input, .kps-form-group .kps-select-option {
    padding: 12px 10px 12px 15px;
    color: rgba(0, 0, 0, .870588);
    font-size: 15px
}

@keyframes heartbeat {
    0%, 2%, 40% {
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

    32%, 4% {
        -webkit-transform: scale(1.08);
        -o-transform: scale(1.08);
        -moz-transform: scale(1.08);
        -ms-transform: scale(1.08);
        transform: scale(1.08)
    }

    24%, 8% {
        -webkit-transform: scale(1.1);
        -o-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1)
    }

    20% {
        -webkit-transform: scale(.96);
        -o-transform: scale(.96);
        -moz-transform: scale(.96);
        -ms-transform: scale(.96);
        transform: scale(.96)
    }
}

@-webkit-keyframes heartbeat {
    0%, 2%, 40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    32%, 4% {
        -webkit-transform: scale(1.08);
        transform: scale(1.08)
    }

    24%, 8% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    20% {
        -webkit-transform: scale(.96);
        transform: scale(.96)
    }
}

*, ::after, ::before {
    box-sizing: border-box
}

.main-app {
    text-align: center;
    margin: 100px
}

.box {
    border-radius: 0 0 6px 6px !important
}

body {
    position: initial;
    overflow-y: auto;
    inset: 0px;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-position: center
}

.main-background-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    max-width: 640px;
    padding-top: 20px;
    padding-bottom: 0px
}

.start-main-background-container {
    max-width: 610px
}

.main-background-container.background-image {
    background-position: unset;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px
}

.main-background-container .background-video {
    position: fixed;
    width: calc(100vw - -33vw);
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.main-background-container .main-inner-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: auto;
    margin: auto;
    max-height: 95vh
}

.start-main-inner-container {
    -webkit-backdrop-filter: unset !important;
    backdrop-filter: unset !important;
    background: 0 0 !important
}

.main-background-container .main-inner-container .kps-chat-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: transparent;
    color: #fff;
    border-radius: 12px 12px 0 0;
    width: 100%;
    margin: auto
}

@media (min-width:767px) {
    .main-background-container .main-inner-container .kps-chat-form {
        display: flex;
        position: absolute
    }

    .start-main-background-container {
        position: unset;
        height: 372px;
        padding-bottom: 0
    }
}

.main-background-container .main-inner-container .kps-chat-form .mobile-voice-container, .main-three-colum-layout .new-mobile-background-video {
    display: none
}

.main-background-container .main-inner-container .kps-chat-form .mobile-voice-container .chat-logo-image {
    width: 100%;
    height: 100%;
    max-width: 60px
}

.main-background-container .main-inner-container .kps-chat-form .mobile-voice-container .mobile-divider {
    width: 100%;
    max-width: 80%;
    border-bottom: 2px solid #0000001a;
    border-radius: 10px;
    margin-top: 12px
}

.main-background-container .main-inner-container .kps-chat-form .mobile-voice-container button {
    background: 0 0;
    border: none
}

.main-background-container .main-inner-container .kps-chat-form .input-container {
    width: 97%;
    height: 100%;
    border-radius: 0 0 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4px auto;
    background: 0 0
}

.main-background-container .main-inner-container .kps-chat-form .input-container .kps-input-box {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    resize: none;
    height: 44px;
    padding: 11px 10px 9px
}

.main-background-container .main-inner-container .kps-chat-form .input-container .kps-input-box::placeholder {
    font-size: 18px
}

.main-background-container .main-inner-container .kps-chat-form .input-container .chat-logo-image {
    width: 90%;
    object-fit: cover;
    max-width: 50px;
    margin: .5px 0 0
}

.main-background-container .main-inner-container .kps-chat-form .input-container button.voice-btn-kps {
    background: 0 0;
    height: 50px;
    width: 50px
}

.main-background-container .main-inner-container .kps-chat-form .input-container button.voice-btn-kps:hover {
    box-shadow: none;
    background-color: transparent
}

.main-background-container .main-inner-container .kps-chat-form .select-dropdown {
    display: none;
    width: 100%;
    height: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 #0000001a;
    background: url("./img/dropdown_icon.png") 0 0/15px no-repeat padding-box #fff;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    outline: 0;
    color: #000;
    border: 1px solid #e0e0e0;
    cursor: pointer;
    padding: 10px 0 10px 20px;
    text-align: left;
    position: relative;
    -webkit-appearance: none;
    background-position: calc(100% - 18px) 18px;
    min-width: 120px
}

.output-area {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 30PX 0PX;
    width: 100%;
    height: 100%;
    min-height: 250px;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    color: #000;
    white-space: pre-wrap;
    max-height: calc(100vh);
    border-radius: 0
}

.output-area .q-and-a-container {
    text-wrap: wrap;
    white-space-collapse: collapse;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    width: 100%
}

.output-area .q-and-a-container .main-q-a-box {
    display: flex;
    flex-direction: column;
    gap: .6rem;
    width: 100%
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
    display: flex;
    align-items: self-start;
    padding-right: 0;
    justify-content: flex-end;
    margin-left: 16ch
}

.client-question, .output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
    width: fit-content;
    max-width: 65ch;
    font-family: roboto, sans-serif;
    border-radius: 21px 21px 5px;
    align-items: center;
    letter-spacing: .2px;
    text-align: left;
    color: #001132;
}

.output-area .q-and-a-container .main-q-a-box .client-msg-bubble .client-questions {
    background: #FFF;
    padding: 14px 20px;
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 10px 0 3px;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    box-shadow: unset;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px)
}

.bacground-client-msg-bubble {
    box-shadow: unset !important
}

.bot-answer, .client-question {
    box-shadow: 0 -2px 4px 0 #00000040 inset;
    font-size: 18px;
    line-height: 24px;
    display: flex
}

.client-question {
    background: #fff;
    padding: 8px 20px;
    font-weight: 400;
    margin: 10px 0;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere
}

.bot-answer, .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
    font-family: roboto, sans-serif;
    font-style: normal;
    letter-spacing: .2px;
    color: #fff;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    font-weight: 400;
    text-align: left
}

.bot-answer {
    background: #000000CC;
    border-radius: 21px;
    padding: 15px 18px 15px 15px;
    align-items: center;
    margin-right: 50px
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
    display: flex;
    justify-content: flex-start
}

@media (max-width:767px) {
    .main-background-container.background-image {
        background-position: center
    }

    .main-background-container {
        width: 100%;
        padding-bottom: 0;
        padding-top: 0;
        max-height: 100%;
        max-width: 100%
    }

    .main-background-container .main-inner-container {
        background: rgba(228, 239, 255, .5);
        max-height: calc((100vh / 1) - 0vh);
        border-radius: 0
    }

    .main-background-container .start-main-inner-container {
        height: 315px
    }

    .main-background-container .main-inner-container .kps-chat-form {
        padding: 0;
        margin: 0;
        max-width: 100%
    }

    .start-form {
        background: #f4f8fe !important
    }

    .main-background-container .main-inner-container .kps-chat-form .mobile-voice-container {
        width: 100%;
        background: #ffffffcc;
        display: flex;
        justify-content: center;
        border-radius: 12px 12px 0 0;
        padding: 15px 0 0;
        flex-direction: column;
        align-items: center
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container {
        border-radius: 0;
        position: relative;
        margin: 0 auto
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container .kps-input-box {
        padding: 12px 0 6px 20px;
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container .chat-logo-image {
        display: block !important;
        margin: .5px 2px 2px
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container button {
        padding: 3px 4px 4px 1px
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container button:hover {
        box-shadow: none;
        background-color: transparent
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container button.voice-btn-kps {
        display: block;
        margin-left: 10px
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container button .msg-send-image {
        margin: -.55px 3px 0 0;
        max-width: 45px
    }

    #scrollbar2::-webkit-scrollbar {
        width: 7px !important
    }

    .main-background-container .main-inner-container .kps-chat-form .select-dropdown {
        max-width: 100%
    }

    .output-area {
        min-height: calc(100vh - 182px);
        display: flex;
        flex-direction: column-reverse
    }

    .output-area .q-and-a-container .main-q-a-box .client-msg-bubble {
        padding-right: 0;
        margin-left: 30px
    }

    .output-area .q-and-a-container .main-q-a-box .bot-msg-bubble {
        margin-right: 0
    }

    .copy {
        padding-left: 3px
    }
}

.output-area .q-and-a-container .main-q-a-box .bot-msg-bubble .answer-for-questions {
    width: auto;
    background: 0 0;
    font-size: 18px;
    line-height: 26px;
    align-items: center
}

.output-area .q-and-a-container .main-q-a-box .answer-for-questions {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
    margin: 10px 0
}

#scrollbar2::-webkit-scrollbar {
    width: 10px;
    background-color: transparent
}

#scrollbar2::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    padding: 2px
}

#scrollbar2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #afabab52;
    margin: 2px;
    border: 2px solid #88888802
}

#scrollbar2::-webkit-scrollbar-thumb:hover {
    background-color: #fff
}

.full-color-bg-kps {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background: #13082a
}

.kps-form-group {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 25px
}

.kps-form-group-password {
    margin-bottom: 10px
}

.kps-form-group label {
    float: left;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0;
    font-size: 15px;
    margin-bottom: 8px;
    font-family: Roboto, sans-serif;
    color: #918b8b
}

.kps-form-group label.full-with-label, .main-three-colum-layout .mobile-navbar-items li:first-child {
    width: 100%
}

.kps-form-group .kps-login-input {
    float: left;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    background: #eeeeee;
    resize: none
}

.kps-form-group .kps-login-input:focus-visible {
    outline: #1956db auto 1px
}

.kps-form-group .kps-select-option {
    float: left;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-family: Roboto, sans-serif;
    background: url(img/dropdown_arrow.png) 0 0/11px no-repeat #fdfdfd;
    -webkit-appearance: none;
    background-position: calc(100% - 13px) 18px
}

.full-with-btn-container .kps-submit-button, .full-with-btn-container .login-btn {
    width: 100%;
    padding: 14px 0;
    letter-spacing: .5px;
    height: auto;
    font-weight: 500;
    float: left;
    font-family: Roboto, sans-serif;
    cursor: pointer;
    text-decoration: none;
    text-align: center
}

.kps-form-group .kps-select-option:focus-visible {
    outline: #1956db auto 1px
}

.full-with-btn-container .login-btn {
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 8px;
    line-height: 25px;
    box-shadow: 0 1px 4px 0 rgba(191, 223, 252, .9);
    font-size: 18px;
    background: linear-gradient(90.09deg, #d7b146 -1.69%, #f47e2e 99.88%);
    border: 0;
    color: #fff;
    text-transform: uppercase
}

.full-with-btn-container .kps-submit-button {
    margin-top: 30px;
    border-radius: 7px;
    line-height: 30px;
    background: #1e88e5;
    border: 0;
    color: #fff
}

.full-with-btn-container .kps-submit-button:hover {
    background: #2196f3
}

.back-button-kps-style {
    position: absolute;
    line-height: 40px;
    top: 20px;
    right: 20px;
    cursor: pointer
}

.back-button-kps-style .back-btn {
    text-decoration: none;
    width: 40px;
    height: 40px;
    opacity: .6;
    display: block;
    border-radius: 50%
}

.back-button-kps-style .back-btn img {
    width: 16px;
    height: 16px;
    position: relative;
    top: 6px;
    left: 3px
}

.back-button-kps-style .back-btn:hover {
    opacity: 1
}

a, button {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s
}

.error-message-container .error-message {
    float: left;
    width: 100%;
    margin-top: 0;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #e53935
}

.loading-area {
    place-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1
}

.loader {
    display: flex;
    flex-direction: row
}

.loading-text {
    padding: 12px 0 5px 1px;
    margin: 0 4px 1px;
    color: #fff
}

.loading-model {
    position: relative;
    width: 22px
}

.loading-model img {
    width: 27px;
    height: auto;
    position: absolute;
    top: 3px;
    right: 5px
}

.loading-area .loader .loading-sphere {
    color: #fff;
    background: #fff;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    transform: scale(0);
    animation: 1.5s ease-in-out infinite animate;
    display: inline-block;
    margin: 20px 4px 4px
}

.loading-area .loader .loading-sphere:first-child {
    color: #fff;
    background: #fff;
    animation-delay: 0s
}

.loading-area .loader .loading-sphere:nth-child(2) {
    color: #fff;
    background: #fff;
    animation-delay: .2s
}

.loading-area .loader .loading-sphere:nth-child(3) {
    color: #fff;
    background: #fff;
    animation-delay: .4s
}

.loading-area .loader .loading-sphere:nth-child(4) {
    animation-delay: .6s
}

.loading-area .loader .loading-sphere:nth-child(5) {
    animation-delay: .8s
}

.loading-area .loader .loading-sphere:nth-child(6) {
    animation-delay: 1s
}

.loading-area .loader .loading-sphere:nth-child(7) {
    animation-delay: 1.2s
}

@keyframes animate {
    0%, 100% {
        transform: scale(.2);
        background-color: #fff
    }

    40%, 50% {
        transform: scale(1);
        background-color: #fff
    }
}

.main-container-login {
    display: block;
    width: 100vw;
    height: 100vh;
    margin: 0;
    top: 0;
    padding: 0;
    background: #00000099;
    position: absolute;
    overflow-y: auto;
    z-index: 1000
}

.main-container-login.dashboard-main-container {
    height: 100%
}

.main-container-login .main-container-inner-login {
    width: 100%;
    display: flex;
    height: 100%;
    margin: auto;
    max-width: 1230px;
    align-items: center;
    justify-content: center
}

.main-container-login .main-container-inner-login .login-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 23em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 650px;
    position: relative;
    z-index: 90;
    margin: auto
}

.main-container-login .main-container-inner-login .login-container .login-card {
    background: #fff;
    border-radius: 18px;
    float: left;
    width: 95%;
    padding: 40px 60px;
    position: relative;
    max-width: 430px
}

.main-container-login .main-container-inner-login .login-container h2 {
    float: left;
    width: 100%;
    margin: 0 0 30px;
    font-weight: 600;
    letter-spacing: 0;
    color: rgba(0, 0, 0, .870588);
    font-family: Poppins, sans-serif;
    text-align: center;
    font-size: 26px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container {
    max-width: 850px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .dashboard-card {
    max-width: 650px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section {
    margin-bottom: 30px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .completion {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    margin-bottom: 7px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .completion-text {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400;
    color: #4caf50;
    font-family: Roboto, sans-serif;
    margin-bottom: 15px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container .submit-button {
    width: 100%;
    max-width: 230px;
    color: #000;
    background: 0 0;
    border: 2px solid #1956db
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container .submit-button:hover {
    background: #f5f5f5
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container .update-button {
    width: 100%;
    max-width: 230px
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .fixed-prompt-section .fixed-prompt {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    margin: 7px auto
}

.main-container-login .main-container-inner-login .login-container.dashboard-container .fixed-prompt-section .update-message {
    float: left;
    width: 100%;
    margin-top: 8px;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: #e53935
}

body, html {
    height: 100%;
    margin: 0
}

.main-three-colum-layout {
    display: flex;
    height: 100%;
    max-height: 100vh;
    width: 100%
}

.main-three-colum-layout .new-background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10
}

.main-three-colum-layout .left-column {
    flex: 0.5 0 auto;
    position: relative
}

@media (max-width:767px) {
    .main-container-login .main-container-inner-login .login-container .login-card {
        height: 100%;
        width: 95%;
        padding: 40px;
        margin-bottom: 0
    }

    .main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container {
        flex-direction: column;
        justify-content: center
    }

    .main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container .submit-button, .main-container-login .main-container-inner-login .login-container.dashboard-container .test-submission-section .full-with-btn-container .update-button {
        max-width: 100%
    }

    .main-three-colum-layout .left-column, .main-three-colum-layout .new-background-video {
        display: none
    }

    .main-three-colum-layout .new-mobile-background-video {
        display: none;
        width: auto;
        height: 102%;
        position: fixed;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .main-three-colum-layout .center-column {
        flex: 1 0 auto;
        width: 100%;
        max-width: 660px
    }
}

.main-three-colum-layout .center-column {
    flex: 1 0 auto;
    width: 100%
}

@media (max-width:995px) and (min-width:768px) {
    .main-background-container.background-image {
        background-position: center
    }

    .main-background-container .main-inner-container .kps-chat-form {
        padding: 0;
        max-width: 95%;
        margin: 0
    }

    .main-background-container .main-inner-container .kps-chat-form .input-container {
        padding: 10px 0
    }

    .main-three-colum-layout .left-column, .main-three-colum-layout .new-background-video {
        display: none
    }

    .main-three-colum-layout .new-mobile-background-video {
        display: none;
        width: 120%;
        height: auto;
        position: fixed;
        top: 50%;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, -50%)
    }

    .main-three-colum-layout .center-column {
        margin: 0 !important;
        max-width: 100%;
        overflow: scroll;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 70px
    }

    .stage-2-3-wrapper {
        padding: 0 30px
    }

    .start-central-column {
        align-items: center !important;
        background: 0 0;
        border-radius: 12px !important;
        display: flex !important;
        flex-direction: column;
        height: 100%;
        max-width: 1200px !important;
        width: 90vw !important
    }
}

.main-three-colum-layout .right-column {
    display: none;
    flex: 0 0 auto;
    border-radius: 10px 0 0 10px;
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(100% - 80px);
    box-shadow: 0 1px 1px 0 hsla(0, 0%, 0%, .02), 0 2px 11px 0 hsla(0, 0%, 0%, .02), 0 1px 8px 0 hsla(0, 0%, 0%, .05)
}

.main-three-colum-layout .navbar {
    height: 100%;
    background: #001a39;
    width: 100%
}

.main-three-colum-layout .navbar-items {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    align-items: center
}

.main-three-colum-layout .navbar-items li {
    text-align: center;
    margin: 20px auto;
    width: 100%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    height: auto
}

@media (max-width:1980px) {
    .main-background-container .main-inner-container .start-form {
        max-width: 1000px !important
    }
}

@media (min-width:1981) {
    .main-background-container .main-inner-container .start-form {
        max-width: 1200px !important
    }
}

.main-three-colum-layout .navbar-items li a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block
}

.main-three-colum-layout .navbar-items li a img {
    width: 100%;
    height: 100%;
    max-width: 40px
}

.main-three-colum-layout .mobile-navbar-items {
    padding: 3px 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0
}

.main-three-colum-layout .mobile-navbar-items li {
    list-style: none;
    cursor: pointer
}

@media (max-width:767px) {
    .main-three-colum-layout .center-column {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto
    }

    .main-three-colum-layout .right-column {
        border-radius: 0;
        top: auto;
        right: auto;
        background: 0 0;
        bottom: auto;
        width: 100%;
        left: auto
    }

    .main-three-colum-layout .navbar {
        border-radius: 0;
        background: url("./img/top_gradient.png") center/cover no-repeat;
        height: auto
    }

    .main-three-colum-layout .navbar-items {
        display: none
    }

    .main-three-colum-layout .navbar-items li {
        padding: 10px;
        margin: 15px auto
    }

    .main-three-colum-layout .mobile-navbar-items li {
        margin: 15px auto
    }
}

@media (max-width:995px) and (min-width:768px) {
    .main-three-colum-layout .right-column {
        border-radius: 0 0 16px 16px;
        background-color: #fff !important;
        top: 0;
        right: auto;
        background: 0 0;
        bottom: auto;
        width: 100%;
        left: auto
    }

    .main-three-colum-layout .navbar {
        border-radius: 0;
        background: #001a39ab
    }

    .main-three-colum-layout .navbar-items {
        display: none
    }

    .main-three-colum-layout .mobile-navbar-items li, .main-three-colum-layout .navbar-items li {
        padding: 10px;
        margin: 15px auto
    }
}

.main-three-colum-layout .mobile-navbar-items li a {
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    text-align: center
}

.main-three-colum-layout .mobile-navbar-items li a img {
    width: 100%;
    height: 100%
}

.main-three-colum-layout .mobile-navbar-items li a img.logo-kps {
    max-width: 220px
}