.vitualtour-wrapper{

}

.vitualtour-container{
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: #000000b8;
    padding: 20px 20px 45px 20px;
    border-radius: 20px;
}

.virtualour-inner{
display: flex;
justify-content: center;
flex-direction: column;
width: 100%;
align-items: center;
margin-top: 10px;
}

.virtualour-inner h3{
font-size: 28px;
text-align: center;
font-family: 'Inter', sans-serif;
font-weight: 600;
}

.virtualour-inner p{
    max-width: 430px;
    font-family: 'Roboto', sans-serif;
color: #bdbdbd;
}

.vitualtour-container img{
    max-width: 430px;
   width: 100%;
}